import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import {useContext, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import {getTutorialImages} from '../components/TutorialOverlay';

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  font-family: ${props => props.fontFamily};
  min-height: 80vh;
  overflow-y: auto;
`;

const Text = styled.div`
  font-family: ${props => props.fontFamily};
  margin: 20px 0;
`;

const SubText = styled.div`
  font-family: ${props => props.fontFamily};
  margin: 10px 0;
`;

const UserInput = styled.input`
  width: 100%;
  height: 40px;
  margin: 10px 0;
  padding: 0px 16px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #ffffff;
  font-family: ${props => props.fontFamily};
`;

const Link = styled.div`
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  margin-top: 15px;
  font-family: ${props => props.fontFamily};
`;

const CheckboxContainer = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const Checkbox = styled.input`
  margin-right: 8px;
`;

const LoginButtonStyled = styled.button`
  width: 100%;
  height: 40px;
  margin-top: 20px;
  padding: 0px 16px;
  border: none;
  border-radius: 20px;
  background-color: #3ddbd1;
  color: #ffffff;
  font-family: ${props => props.fontFamily};
  cursor: pointer;
`;

const LanguageSwitch = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
`;

const LanguageButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-family: ${props => props.fontFamily};
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  color: ${props => (props.active ? '#000' : '#888')};
`;

const PrivacyLink = styled.a`
  color: #888;
  text-decoration: none;
  font-size: 12px;
  font-family: ${props => props.fontFamily};
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  &:hover {
    text-decoration: underline;
  }
`;

// Component for Login Button
const LoginButton = ({onClick, text, fontFamily}) => {
  return (
    <LoginButtonStyled fontFamily={fontFamily} onClick={onClick}>
      {text}
    </LoginButtonStyled>
  );
};

const Login = props => {
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language, switchLanguage} = useContext(LanguageContext);

  const [userIdentifier, setUserIdentifier] = useState(
    localStorage.getItem('rememberUserIdentifier') || '',
  );
  const [password, setPassword] = useState(
    localStorage.getItem('rememberPassword') || '',
  );
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem('rememberMe') === 'true',
  );
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const rememberMe = Cookies.get('rememberMe') === 'true';
    setRememberMe(rememberMe);
    if (rememberMe) {
      setUserIdentifier(Cookies.get('rememberUserIdentifier') || '');
    }
  }, []);

  const handleLanguageSwitch = lang => {
    switchLanguage(lang);
    localStorage.setItem('Language', lang);
  };

  const defaultProps = {
    text: translate(language, 'login.welcomeBack'),
    subText: translate(language, 'login.signInToAccess'),
    userInput: translate(language, 'login.email'),
    userPassword: translate(language, 'login.password'),
  };

  const validateUserIdentifier = identifier => {
    // Email regex
    const emailRe =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\\.,;:\s@"]+\.)+[^<>()[\\.,;:\s@"]{2,})$/i;
    // Phone number regex (simple example, adjust as needed)
    const phoneRe = /^\+?[1-9]\d{1,14}$/;
    return emailRe.test(identifier) || phoneRe.test(identifier);
  };

  const handleLogin = async () => {
    setError('');
    setLoading(true);

    // Validate the userIdentifier format
    if (!validateUserIdentifier(userIdentifier)) {
      setError(translate(language, 'login.invalidUserIdentifier'));
      setLoading(false);
      return;
    }

    // Check if the userIdentifier is filled
    if (!userIdentifier) {
      setError(translate(language, 'login.userIdentifierRequired'));
      setLoading(false);
      return;
    }

    // Check if the password is filled
    if (!password) {
      setError(translate(language, 'login.passwordRequired'));
      setLoading(false);
      return;
    }

    try {
      // Make the login request to the backend
      const response = await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/login`,
        {
          userIdentifier: userIdentifier,
          userPassword: password,
        },
        {withCredentials: true},
      );

      // Check if the login was successful (HTTP 200)
      if (response.status === 200) {
        // Handle "Remember Me" functionality
        if (rememberMe) {
          Cookies.set('rememberUserIdentifier', userIdentifier);
          Cookies.set('rememberMe', 'true');
        } else {
          Cookies.remove('rememberUserIdentifier');
          Cookies.remove('rememberMe');
        }

        // Navigate to the home page
        navigate('/');
      } else {
        setError('Login failed. Unexpected response.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(
        error.response?.data?.message || 'Failed to login. Please try again.',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleBiometricLogin = async () => {
    const isNative = navigator.userAgent.includes('wv');

    if (isNative) {
      // If it's a WebView, trigger native biometric login via postMessage
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'biometricLogin',
        }),
      );
      return;
    }

    const credentialId = localStorage.getItem('credentialId');

    if (!credentialId || !window.PublicKeyCredential) {
      alert('Biometric login is not available or supported on this device.');
      return;
    }

    try {
      // Step 1: Generate a new challenge
      const challenge = new Uint8Array(32);
      window.crypto.getRandomValues(challenge);

      // Step 2: Create PublicKeyCredentialRequestOptions
      const publicKey = {
        challenge: challenge,
        allowCredentials: [
          {
            id: Uint8Array.from(atob(credentialId), c => c.charCodeAt(0)),
            type: 'public-key',
          },
        ],
        userVerification: 'required', // Requires biometric input
        timeout: 60000,
      };

      // Step 3: Use navigator.credentials.get to authenticate
      const assertion = await navigator.credentials.get({publicKey});

      // Step 4: Prepare the assertion for backend verification
      const assertionResponse = {
        id: assertion.id,
        rawId: btoa(String.fromCharCode(...new Uint8Array(assertion.rawId))),
        type: assertion.type,
        response: {
          clientDataJSON: btoa(
            String.fromCharCode(
              ...new Uint8Array(assertion.response.clientDataJSON),
            ),
          ),
          authenticatorData: btoa(
            String.fromCharCode(
              ...new Uint8Array(assertion.response.authenticatorData),
            ),
          ),
          signature: btoa(
            String.fromCharCode(
              ...new Uint8Array(assertion.response.signature),
            ),
          ),
          userHandle: assertion.response.userHandle
            ? btoa(
                String.fromCharCode(
                  ...new Uint8Array(assertion.response.userHandle),
                ),
              )
            : null,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/biometric_verify`,
        {
          credential: {
            ...assertionResponse,
            userId: assertion.id,
            signature: assertionResponse.response.signature, //missing
            challenge: btoa(String.fromCharCode(...challenge)),
          },
        },
      );
      if (response.status === 200) {
        navigate('/');
      } else {
        setError(translate(language, 'login.serverAuthenticationFailed'));
      }
    } catch (error) {
      console.error('Biometric login error:', error);
      alert(error);
      setError(
        translate(language, 'login.biometricFailed') +
          (error.response?.data?.message ||
            translate(language, 'login.tryAgain')),
      );
    } finally {
      setLoading(false);
    }
  };

  const tutorialImages = getTutorialImages();

  return (
    <>
      <Container fontFamily={fontFamilies.regular}>
        <LanguageSwitch>
          <LanguageButton
            fontFamily={fontFamilies.regular}
            active={language === 'en'}
            onClick={() => handleLanguageSwitch('en')}>
            EN
          </LanguageButton>
          <LanguageButton
            fontFamily={fontFamilies.regular}
            active={language === 'zh'}
            onClick={() => handleLanguageSwitch('zh')}>
            简
          </LanguageButton>
          <LanguageButton
            fontFamily={fontFamilies.regular}
            active={language === 'zhhk'}
            onClick={() => handleLanguageSwitch('zhhk')}>
            繁
          </LanguageButton>
        </LanguageSwitch>
        <Text fontFamily={fontFamilies.bold}>
          {props.text ?? defaultProps.text}
        </Text>
        <SubText fontFamily={fontFamilies.regular}>
          {props.subText ?? defaultProps.subText}
        </SubText>
        {error && <div style={{color: 'red'}}>{error}</div>}
        <UserInput
          fontFamily={fontFamilies.light}
          type="text"
          placeholder={translate(language, 'login.userIdentifier')}
          value={userIdentifier}
          onChange={e => setUserIdentifier(e.target.value)}
        />
        <UserInput
          fontFamily={fontFamilies.light}
          type="password"
          placeholder={props.userPassword ?? defaultProps.userPassword}
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            id="rememberMe"
            checked={rememberMe}
            onChange={e => setRememberMe(e.target.checked)}
          />
          <label htmlFor="rememberMe">
            {translate(language, 'login.rememberMe')}
          </label>
        </CheckboxContainer>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <LoginButton
              onClick={handleLogin}
              text={translate(language, 'login.logIn')}
              fontFamily={fontFamilies.semiBold}
            />
            <LoginButton
              onClick={handleBiometricLogin}
              text={translate(language, 'login.biometricLogin')}
              fontFamily={fontFamilies.semiBold}
            />
          </>
        )}
        <Link
          fontFamily={fontFamilies.light}
          onClick={() =>
            navigate('/forgotPassword', {state: {showBackIcon: true}})
          }>
          {translate(language, 'login.forgotPassword')}
        </Link>
        <Link
          fontFamily={fontFamilies.light}
          onClick={() => navigate('/signup', {state: {showBackIcon: true}})}>
          {translate(language, 'login.notAMember')}
        </Link>
        <PrivacyLink
          href="http://carbonpoint.io/privacy"
          target="_blank"
          rel="noopener noreferrer"
          fontFamily={fontFamilies.light}>
          {translate(language, 'login.privacyPolicy')}
        </PrivacyLink>
      </Container>
      {/* Preloading tutorial images */}
      {tutorialImages.map(src => (
        <img key={src} src={src} style={{display: 'none'}} alt="tutorial" />
      ))}
    </>
  );
};

export default Login;

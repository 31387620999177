import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';

const BottomNav = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);

  const [isMobile, setIsMobile] = useState(
    window.innerWidth / window.innerHeight < 1,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth / window.innerHeight < 1);
    };

    // Set up event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const styles = {
    placeholder: {
      height: '56px',
      paddingTop: '50px',
    },
    Bar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      height: '76px',
      backgroundColor: '#ffffff',
      borderTop: '1px solid #f0f0f0',
      boxSizing: 'border-box',
      boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
      minWidth: '300px',
      zIndex: 1000,
    },
    Icons: {
      color: '#030303',
      fill: '#030303',
      fontSize: '35px',
      width: '35px',
      height: '30px',
      opacity: 0.5,
      paddingBottom: '6px',
    },
    IconContainer: {
      // Style for the container of the icon and text
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    IconText: {
      // Style for the text under the icon
      fontFamily: fontFamilies.light,
      fontSize: '10px',
    },
    ImageContainer: {
      marginTop: '-32px',
      width: '80px',
      height: '60px',
      borderRadius: '0px',
      backgroundImage: 'url(./image.jpeg)',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      zIndex: 1,
    },
  };

  const HomeIconComponent = () => {
    return (
      <div style={styles.IconContainer} onClick={() => navigate('/')}>
        <svg style={styles.Icons} viewBox="0 0 24 24">
          <path
            fill={location.pathname === '/' ? '#3DDBD1' : '#26454a'}
            d="m12 5.69 5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3 2 12h3v8h6v-6h2v6h6v-8h3L12 3z"></path>
        </svg>
        <span
          style={{
            ...styles.IconText,
            color: location.pathname === '/' ? '#3DDBD1' : '#26454a',
          }}>
          {translate(language, 'bottomNav.home')}
        </span>
      </div>
    );
  };

  const ExploreComponent = () => {
    return (
      <div style={styles.IconContainer} onClick={() => navigate('/explorer')}>
        <svg style={styles.Icons} viewBox="0 0 24 24">
          <path d="M0 0h24v24H0V0z" fill="none"></path>
          <path
            fill={location.pathname === '/explorer' ? '#3DDBD1' : '#26454a'}
            strokeWidth="5"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-5.5-2.5 7.51-3.49L17.5 6.5 9.99 9.99 6.5 17.5zm5.5-6.6c.61 0 1.1.49 1.1 1.1s-.49 1.1-1.1 1.1-1.1-.49-1.1-1.1.49-1.1 1.1-1.1z"></path>
        </svg>
        <span
          style={{
            ...styles.IconText,
            color: location.pathname === '/explorer' ? '#3DDBD1' : '#26454a',
          }}>
          {translate(language, 'bottomNav.explorer')}
        </span>
      </div>
    );
  };
  const RewardsComponent = () => {
    return (
      <div style={styles.IconContainer} onClick={() => navigate('/redeem')}>
        <svg style={styles.Icons} viewBox="0 0 24 24">
          <path d="M0 0h24v24H0V0z" fill="none"></path>
          <path
            fill={location.pathname === '/redeem' ? '#3DDBD1' : '#26454a'}
            d="M20 6h-2.18c.11-.31.18-.65.18-1a2.996 2.996 0 0 0-5.5-1.65l-.5.67-.5-.68C10.96 2.54 10.05 2 9 2 7.34 2 6 3.34 6 5c0 .35.07.69.18 1H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-5-2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM9 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm11 15H4v-2h16v2zm0-5H4V8h5.08L7 10.83 8.62 12 11 8.76l1-1.36 1 1.36L15.38 12 17 10.83 14.92 8H20v6z"></path>
        </svg>
        <span
          style={{
            ...styles.IconText,
            color: location.pathname === '/redeem' ? '#3DDBD1' : '#26454a',
          }}>
          {translate(language, 'bottomNav.redeem')}
        </span>
      </div>
    );
  };

  const SavedComponent = () => {
    return (
      <div style={styles.IconContainer} onClick={() => navigate('/saved')}>
        <svg style={styles.Icons} viewBox="0 0 24 24">
          <path d="M0 0h24v24H0V0z" fill="none"></path>
          <path
            fill={location.pathname === '/saved' ? '#3DDBD1' : '#26454a'}
            d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z"></path>
        </svg>
        <span
          style={{
            ...styles.IconText,
            color: location.pathname === '/saved' ? '#3DDBD1' : '#26454a',
          }}>
          {translate(language, 'bottomNav.saved')}
        </span>
      </div>
    );
  };

  const Image = props => {
    return (
      <div style={styles.IconContainer} onClick={() => navigate('/profile')}>
        <div
          style={{
            ...styles.ImageContainer,
            backgroundImage: `url(${props.image ?? defaultProps.image})`,
          }}
        />
        <span
          style={{
            ...styles.IconText,
            marginTop: '6px',
            color: location.pathname === '/profile' ? '#3DDBD1' : '#26454a',
          }}>
          {translate(language, 'bottomNav.profile')}
        </span>
      </div>
    );
  };

  const defaultProps = {
    HomeIconComponent,
    ExploreComponent,
    RewardsComponent,
    SavedComponent,
    image: '/assets/icons/buttonIcon.png',
  };

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Adjust the styles based on the isMobile state
  const barStyle = {
    ...styles.Bar,
    display: isMobile ? 'flex' : 'none', // Hide the bar in desktop view
  };

  return (
    <>
      <div style={styles.placeholder} />
      <div style={barStyle}>
        {props.children}
        <div></div>
        <HomeIconComponent></HomeIconComponent>
        <ExploreComponent></ExploreComponent>
        <Image></Image>
        <RewardsComponent></RewardsComponent>
        <SavedComponent></SavedComponent>
        <div></div>
      </div>
    </>
  );
};

export default BottomNav;
